<template>
  <div class="article">
    <BackButton/>

    <p>
      Wir sind Christen aus Österreich, die davon überzeugt sind, dass der Tod und die Auferstehung von Jesus Christus
      das zentralste Ereignis in der Geschichte sind. Wenn sie nicht tatsächlich stattgefunden haben, <b>ist das ganze
      Christentum eine Lüge.</b><br><br>
      Wenn aber schon, ist Jesus' Angebot eines ewigen Lebens über den Tod hinaus <b>die wichtigste Botschaft der
      Welt</b>, weil sie durch die Auferstehung <b>glaubwürdig</b> wird.
      <br><br>
      Deshalb möchten wir dazu einladen, sich mit den wissenschaftlichen Fakten zu befassen, um der Sache auf den Grund zu gehen.
    </p>
    <br><br>
    <h3 class="label">Impressum</h3>
    <div class="text">

      <p class="">
        Timo Zikeli<br>
        Färbermühlgasse 13/5/12<br>
        1230 Wien<br>
        Österreich<br>

        Email: <a href="mailto:kontakt@wersglaubt_SPAMPROTECTION_.at"
                  onclick="this.href=this.href.replace(/_SPAMPROTECTION_/,'')">kontakt [at] wersglaubt.at</a>
      </p>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import BackButton from "@/components/BackButton";

@Component({
  components: {BackButton},
})
export default class Impressum extends Vue {

}
</script>
<style scoped lang="scss">

p {
  line-height: 1.5;
}

.article {

  margin-top: 60px;
  margin-left: auto;
  margin-right: auto;

  $titleFontSize: 40px;


  width: 90vw;
  max-width: 1000px;

  .label {
    font-weight: 600;
    text-align: left;
    font-size: $titleFontSize;
  }

  .text {
    font-size: 20px;
    line-height: 30px;

    padding: 15px;

    p {
      display: inline-block;
      text-align: left;
    }
  }
}
</style>
<style>

</style>
